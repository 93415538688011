<template>
    <div class="app-page-title mx-1">
        <notifications :duration="5000" style="font-size: 140px !important; margin-right: 40px !important;"/>
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <!-- <div class="page-title-icon">
                    <i :class="icon"/>
                </div> -->
                <div>
                    <!-- {{heading}} -->
                    <a-breadcrumb class="">
                        <a-breadcrumb-item>{{ $route.name[0] }}</a-breadcrumb-item>
                        <a-breadcrumb-item v-if="$route.name[1]">{{ $route.name[1] }}</a-breadcrumb-item>
                        <a-breadcrumb-item v-if="$route.name[2]">{{ $route.name[2] }}</a-breadcrumb-item>
                        <a-breadcrumb-item v-if="$route.name[3]">{{ $route.name[3] }}</a-breadcrumb-item>
                    </a-breadcrumb>

                    <!-- <a-breadcrumb v-if="$route.matched[0].name == null || $route.matched[0].name.includes($route.matched[1].name)" class="mb-4">
                        <a-breadcrumb-item>{{ $route.matched[0].name == null != null ? $route.matched[0].name : $route.matched[1].name }}</a-breadcrumb-item>
                    </a-breadcrumb> -->
                    <!-- <div
                        class="page-title-subheading">
                        {{subheading}}
                    </div> -->
                </div>
            </div>
            <div class="page-title-actions">
                <!-- <button type="button" class="btn-shadow mr-3 btn btn-dark">
                    <font-awesome-icon icon="star"/>
                </button>
                <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success">
                    <font-awesome-icon class="mr-2" icon="plus"/>
                    Create New
                </button> -->
            </div>
        </div>
    </div>
</template>

<script>
    import {library} from '@fortawesome/fontawesome-svg-core'
    import {
        faStar,
        faPlus
    } from '@fortawesome/free-solid-svg-icons'
    // import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

    library.add(
        faStar,
        faPlus,
    );

    export default {
        components: {
            // 'font-awesome-icon': FontAwesomeIcon,
        },
        props: {
            icon: String,
            heading: String,
            subheading: String,

        },
        // created() {
        //     // eslint-disable-next-line no-console
        //     // console.log(this.$route)
        // }
    }
</script>
